<template>
  <div v-if="this.$route.name == 'Admin_Finance'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Sales', route: 'Admin_Finance' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-12">
          <Admin_Finance_CreditReceiptsDatatable />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Admin_Finance_CreditReceiptsDatatable from "@/components/Admin.Finance.CreditReceiptsDatatable.vue";
import Navigation from "@/components/Navigation.vue";
export default {
  name: "Admin_Businesses",
  setup() {
    const store = useStore();

    return { store };
  },
  components: {
    Navigation,
    Admin_Finance_CreditReceiptsDatatable,
  },
};
</script>

<style scoped>
</style>