<template>
  <div>
    <div class="row" style="margin-bottom: 30px">
      <div class="col-10">
        <form class="form">
          <label>Filter by Organisation</label>
          <div class="multiselect-placeholder">
            <div class="multiselect-btn" v-on:click="OpenDropdown()">
              Filter by organisations
              <span class="badge" v-if="organisations.length > 0">
                {{ organisations.length }}
              </span>
            </div>
            <div class="options" id="options">
              <div
                class="option"
                v-for="organisation in organisationslist"
                :key="organisation.id"
                v-on:click="SelectOrganisationFilter(organisation.id)"
                v-bind:class="
                  organisations.includes(organisation.id) ? 'selected' : ''
                "
              >
                {{ organisation.name }}
                <span class="silent"> Organisation</span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-2">
        <div class="btn btn-green" v-on:click="GetDataSearch">Search</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table>
          <thead>
            <tr>
              <td
                v-for="column in columns"
                :key="column.name"
                @click="column.sortable === true ? sortBy(column.name) : ''"
                :class="
                  sortKey === column.name
                    ? sortOrders[column.name] > 0
                      ? 'headerSortUp'
                      : 'headerSortDown'
                    : ''
                "
                :style="'width:' + column.width + ';' + 'cursor:pointer;'"
              >
                {{ column.label }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tblrows" :key="row.id">
              <td>{{ row.organisation }}</td>
              <td>{{ row.business }}</td>
              <td>{{ row.author }}</td>
              <td>{{ row.credits }}</td>
              <td>{{ TimeParse(row.created_at) }}</td>
              <td>{{ PaymentStatus(row.payment_status) }}</td>
            </tr>
            <tr v-if="tblrows.length < 1">
              <td colspan="6">No Sales Available</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        Displaying {{ count }} of {{ total_filter }}. Total of {{ total }} rows.
      </div>
      <div class="col-6">
        <div class="btn-group float-right">
          <div class="btn btn-green" v-on:click="GetDataPrev">
            &larr; PREVIOUS
          </div>
          <div class="btn btn-green" v-on:click="GetDataNext">NEXT &rarr;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Admin_Finance_CreditReceiptsDatatable",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    let sortOrders = {};
    let columns = [
      {
        width: "auto",
        label: "Organisation",
        name: "businesses_creditorders.organisation_id",
        sortable: true,
      },
      {
        width: "auto",
        label: "Business",
        name: "businesses_creditorders.business_id",
        sortable: true,
      },
      {
        width: "auto",
        label: "Authorised User",
        name: "businesses_creditorders.author",
        sortable: true,
      },
      {
        width: "auto",
        label: "Credits",
        name: "businesses_creditorders.credits",
        sortable: true,
      },
      {
        width: "auto",
        label: "Created At",
        name: "businesses_creditorders.created_at",
        sortable: true,
      },
      {
        width: "auto",
        label: "Payment Status",
        name: "businesses_creditorders.payment_status",
        sortable: false,
      },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      tblrows: [],
      count: 0,
      order: [3, "DESC"],
      search: "",
      offset: 0,
      total: 0,
      limit: 20,
      total_filter: 0,
      next: 0,
      prev: 0,
      columns: columns,
      sortKey: "businesses_creditorders.created_at",
      sortOrders: sortOrders,
      organisations: [],
      organisationslist: [],
      expanded: false,
    };
  },
  methods: {
    GetData() {
      let body = {
        order: this.order,
        limit: this.limit,
        offset: this.offset,
        organisations: this.organisations,
      };
      this.store
        .dispatch("Admin/GetBusienssCreditsDatatable", body)
        .then((result) => {
          this.total = result.total;
          this.total_filter = result.total_filter;
          this.tblrows = result.data;
          this.count = this.offset + this.tblrows.length;
          this.prev =
            this.offset - this.limit < 0 ? 0 : this.offset - this.limit;
          this.next =
            this.offset + this.tblrows.length > this.total_filter
              ? this.total_filter
              : this.offset + this.tblrows.length;
        })
        .catch((err) => {
          console.log(err);
          this.total = 0;
          this.total_filter = 0;
          this.tblrows = [];
          this.count = 0;
          this.prev = 0;
          this.next = 0;
        });

      var dropdown = document.getElementById("options");
      dropdown.style.display = "none";
      this.expanded = false;
    },
    GetDataSearch() {
      this.tblrows = [];
      this.offset = 0;

      this.GetData();
    },
    GetDataNext() {
      if (this.next != this.total_filter) {
        console.log(this.next);
        this.offset = this.next;
        this.GetData();
      }
    },
    GetDataPrev() {
      if (this.offset !== 0) {
        this.offset = this.prev;
        this.GetData();
      }
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      let name = this.getIndex(this.columns, "name", key);
      let dir = this.sortOrders[key] > 0 ? "ASC" : "DESC";
      this.order = [name, dir];

      this.GetData();
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    PaymentStatus(value) {
      switch (value) {
        case 0:
          return "Pending Invoice";
        case 1:
          return "Invoiced";
        case 2:
          return "Paid";
        case 3:
          return "Refunded";
        default:
          return "N/A";
      }
    },
    GetOrganisationList() {
      this.store
        .dispatch("Admin/GetListOfOrganisations")
        .then((data) => {
          this.organisationslist = data;
        })
        .catch((err) => {
          console.log(err.messages);
        });
    },
    OpenDropdown() {
      var dropdown = document.getElementById("options");
      if (!this.expanded) {
        dropdown.style.display = "block";
        this.expanded = true;
      } else {
        dropdown.style.display = "none";
        this.expanded = false;
      }
    },
    SelectOrganisationFilter(id) {
      if (this.organisations.includes(id)) {
        var index = this.organisations.indexOf(id);
        this.organisations.splice(index, 1);
      } else {
        this.organisations.push(id);
      }
    },
    addToOrganisationsList(id) {
      if (this.organisations.includes(id)) {
        var index = this.organisations.indexOf(id);
        this.organisations.splice(index, 1);
      } else {
        this.organisations.push(id);
      }
    },
    async DownloadSalesNote() {
      this.store
        .dispatch("Admin/DownloadTestsData", this.tests)
        .then((result) => {
          var arrays = [];
          result.forEach((rows) => {
            arrays.push({
              Organisation: rows.organisation || "N/A",
              "Business / Practice": rows.business || rows.vet_practice_name,
              Author: rows.username,
              Address: rows.address,
              County: "N/A",
              Postcode: rows.postcode,
              "Animal Type": "Cattle",
              "Animal Ref": rows.cow_id,
              "Date of Test": rows.created_at,
              "Strongyle (EPG)": rows.strongyle,
              "Nematodirus (EPG)": rows.nematodirus,
              "Coccidia (OPG)": rows.coccidia,
              "Bucket File": rows.bucket_file,
            });
          });
          const items = arrays;
          const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
          const header = Object.keys(items[0]);
          const csv = [
            header.join(","), // header row first
            ...items.map((row) =>
              header
                .map((fieldName) =>
                  JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '""')
                )
                .join(",")
            ),
          ].join("\r\n");

          const data = "data:text/csv;charset=utf-8," + csv;
          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", "export.csv");
          link.click();
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  mounted() {
    this.GetData();
    this.GetOrganisationList();
  },
};
</script>

<style>
table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-weight: 300;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 20px;
  font-weight: normal;
  text-align: center;
}
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
}
table .selected {
  background: rgba(53, 187, 164, 0.7) !important;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
tr:nth-child(even) td {
  border: 1px solid #dddddd;
}
.float-right {
  float: right;
}
.headerSortDown:after,
.headerSortUp:after {
  content: " ";
  position: relative;
  left: 2px;
  border: 8px solid transparent;
}
.headerSortDown:after {
  top: 10px;
  border-top-color: silver;
}
.headerSortUp:after {
  bottom: 15px;
  border-bottom-color: silver;
}
.headerSortDown,
.headerSortUp {
  padding-right: 10px;
}

a + .btn {
  text-decoration: none;
  color: inherit;
}

.multiselect-placeholder {
  max-width: calc(400px);
  width: 100%;
  z-index: 100;
  position: absolute;
}
.multiselect-btn {
  height: auto;
  background: #fff;
  border: 1px solid #c8ced5;
  font-size: 17px;
  border-radius: 10px;
  color: #4d4d4d !important;
  text-align: center;
  display: block;
  box-sizing: border-box;
  padding: 5px;
}
.multiselect-btn .badge {
  height: auto;
  width: auto;
  padding: 0 5px;
  background: #35bba4;
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  display: flex;
  align-content: center;
  justify-content: center;
  float: right;
}
.options {
  display: none;
  background: #fff;
  max-height: 200px;
  height: auto;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 5px;
  margin-top: -6px;
  border-bottom: 1px solid #c8ced5;
  border-left: 1px solid #c8ced5;
  border-right: 1px solid #c8ced5;
  padding-top: 15px;
  border-radius: 0px 0px 10px 10px;
  z-index: 100;
  width: auto;
}
.multiselect-placeholder ::-webkit-scrollbar {
  display: none;
}
.options .option {
  display: block;
  text-align: left;
  height: auto;
  line-height: 20px;
  font-size: 16px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  margin-bottom: 5px;
}
.options .option .silent {
  display: block;
  font-size: 10px;
  color: #4c4c4c;
}
.options .selected {
  border-right: 3px solid #0e836e;
}
</style>